<template>
    <div>
        <div
            v-if="showHeader"
            class="copy-text-header">
            <div class="copy-text-title">
                {{ headerLabel }}
            </div>
            <div class="copy-text-status">
                <div class="copy-text-status-title">
                    STATUS
                </div>
                <div
                    class="status-ball"
                    :class="{'valid': headerStatus}" />
                <div v-if="!headerStatus">
                    Not Detected
                </div>
                <div v-else>
                    Active
                </div>
            </div>
        </div>
        <div
            :style="customPadding ? `padding: ${customPadding}px` : ''"
            :class="{'copy-text-area': true}">
            <div
                ref="content"
                class="content"
                contenteditable
                @click="onContentClick">
                <slot />
            </div>
            <div
                v-if="!hideDownload || !hideCopy || !hideEmail"
                class="actions">
                <action-button
                    v-if="!hideCopy"
                    icon="copy"
                    @click="copyContent" />
                <action-button
                    v-if="!hideEmail"
                    icon="mail"
                    @click="openEmail" />
                <action-button
                    v-if="!hideDownload"
                    icon="download"
                    @click="downloadContent" />
            </div>
        </div>
    </div>
</template>
<script>
import downloadFile from '../../helpers/downloadFile';
import ActionButton from './ActionButton';
import * as clipboard from 'clipboard-polyfill';

export default {
    components: {
        ActionButton,
    },
    props: {
        emailSubject: {
            type: String,
            default: ''
        },
        emailTo: {
            type: String,
            default: ''
        },
        hideEmail: {
            type: Boolean,
            default: false
        },
        hideCopy: {
            type: Boolean,
            default: false
        },
        hideDownload: {
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: false
        },
        headerLabel: {
            type: String,
            default: ''
        },
        headerStatus: {
            type: Boolean,
            default: false
        },
        customPadding: {
            type: [String, Number],
            default: ''
        },
        downloadFilename: {
            type: String,
            default: 'content.txt'
        }
    },
    data() {
        return {
            content: null
        };
    },
    computed: {
        mailToUrl() {
            return `mailto:${encodeURIComponent(this.emailTo)}?subject=${encodeURIComponent(this.emailSubject)}&body=${encodeURIComponent(this.$refs.content.innerText)}`;
        }
    },
    methods: {
        onContentClick() {
            const range = document.createRange();
            range.selectNodeContents(this.$refs.content);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        },
        openEmail() {
            window.open(this.mailToUrl);
        },
        copyContent() {
            const dt = new clipboard.DT();
            dt.setData('text/plain', this.$refs.content.innerText);
            dt.setData('text/html', this.$refs.content.innerHTML);
            clipboard.write(dt);
            this.$flash('Content copied to clipboard!', 'green');
        },
        downloadContent() {
            downloadFile(this.$refs.content.innerText, this.downloadFilename);
        }
    }
};
</script>
<style lang="scss" scoped>
.copy-text-header {
    background: #F2F3F5;
    border: 1px solid rgba(143, 158, 166, 0.4);
    transform: translateY(1px);
    position: relative;
    z-index: 1;
    border-bottom: none;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.copy-text-title {
    font-size: 16px;
    font-weight: 600;
}
.copy-text-status-title {
    font-size: 10px;
    color: #8F9EA6;
}
.copy-text-status {
    display: flex;
    align-items: center;
    font-size: 12px;
}
.status-ball {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #F66B5C;
    margin-left: 8px;
    margin-right: 8px;
    &.valid {
        background: $success-500;
    }
}
.copy-text-area {
    color: $gray-dark;
    border: 1px solid rgba($gray, 0.4);
    padding: 20px;
    background-color: rgba($gray, 0.05);
    position: relative;
    .content {
        overflow-x: scroll;
        &:hover {
            opacity: 0.8;
        }
        &:focus,
        &:active {
            outline: none;
        }
    }
    .actions {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: rgba($gray, 0.05);
        padding: 5px;
    }
}
</style>
